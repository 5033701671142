import * as yup from 'yup';

type PiniataType =
  | 'Minecraft'
  | 'Spiderman'
  | 'Ninjago'
  | 'Myszka Minnie'
  | 'Myszka Micky'
  | 'Jednorożec'
  | 'Batman'
  | 'LOL'
  | 'Psi patrol'
  | 'Piłka'
  | 'Emotki'
  | 'Pirat'
  | 'Kraina Lodu'
  | 'Bluey'
  | 'Stich'
  | '';

type Cake = {
  id: string;
  size: string;
  flavor: string;
  cakeText: string;
  wafer: boolean;
  waferType?: number;
  candle: boolean;
  candleNumber?: string;
  cakeFountain: boolean;
};

type BalloonWithNumber = {
  id: string;
  number?: number;
};

type Piniata = {
  id: string;
  type: PiniataType;
};

type FoodFormData = {
  cake: Cake[];
  ownCake: number;
  kidChampagne: number;
  pizzaMargherita: number;
  pizzaMargheritaHam: number;
  fries: number;
  nuggets: number;
  fruits: number;
  iceCream: number;
  balloon: number;
  balloonWithNumber: BalloonWithNumber[];
  piniata: Piniata[];
  notes: string;
};

const foodSchema = yup.object<Record<keyof FoodFormData, yup.AnySchema>>({
  cake: yup.array().of(
    yup.object<Record<keyof Cake, yup.AnySchema>>({
      id: yup.string().required(),
      size: yup.string().required('Wybierz rozmiar tortu'),
      flavor: yup.string().required('Wybierz smak'),
      cakeText: yup.string(),
      wafer: yup.boolean(),
      waferType: yup
        .number()
        .when('wafer', {
          is: true,
          then: yup
            .number()
            .min(1, 'Wybierz wzór opłatka pomiędzy 1 a 48 ')
            .max(48, 'Wybierz wzór opłatka pomiędzy 1 a 48 ')
            .required('Wybór wzoru jest wymagany')
        })
        .nullable(),
      candle: yup.boolean(),
      candleNumber: yup.string().when('candle', {
        is: true,
        then: yup
          .string()
          .required('To pole jest wymagane, jeśli wybrano świeczkę na torcie')
      }),
      cakeFountain: yup.boolean()
    })
  ),
  ownCake: yup.number().min(0).notRequired(),
  kidChampagne: yup.number().min(0).notRequired(),
  pizzaMargherita: yup.number().min(0).notRequired(),
  pizzaMargheritaHam: yup.number().min(0).notRequired(),
  fries: yup.number().min(0).notRequired(),
  nuggets: yup.number().min(0).notRequired(),
  fruits: yup.number().min(0).notRequired(),
  iceCream: yup.number().min(0).notRequired(),
  balloon: yup.number().min(0).notRequired(),
  balloonWithNumber: yup.array().of(
    yup.object<Record<keyof BalloonWithNumber, yup.AnySchema>>({
      id: yup.string().required(),
      number: yup
        .number()
        .min(0, 'Cyfra nie moze być mniejsza niż 0')
        .max(9, 'Cyfra nie może być większa niż 9')
        .required('Cyfra jest wymagana')
    })
  ),
  piniata: yup.array().of(
    yup.object<Record<keyof Piniata, yup.AnySchema>>({
      id: yup.string().required(),
      type: yup.string().required('Wybierz wzór piniaty')
    })
  ),
  notes: yup.string().notRequired()
});

const cakeInitialValues: Cake = {
  id: '',
  size: '',
  flavor: '',
  cakeText: '',
  wafer: false,
  waferType: undefined,
  candle: false,
  candleNumber: '',
  cakeFountain: false
};

const ballonWithNumberInitialValues: BalloonWithNumber = {
  id: '',
  number: undefined
};

const piniataInitialValues: Piniata = {
  id: '',
  type: ''
};

const foodFormInitialValues: FoodFormData = {
  cake: [],
  ownCake: 0,
  kidChampagne: 0,
  pizzaMargherita: 0,
  pizzaMargheritaHam: 0,
  fries: 0,
  nuggets: 0,
  fruits: 0,
  iceCream: 0,
  balloon: 0,
  balloonWithNumber: [],
  piniata: [],
  notes: ''
};

const piniataTypes = [
  'Minecraft',
  'Spiderman',
  'Ninjago',
  'Myszka Minnie',
  'Myszka Micky',
  'Jednorożec',
  'Batman',
  'LOL',
  'Psi patrol',
  'Piłka',
  'Emotki',
  'Pirat',
  'Kraina Lodu',
  'Bluey',
  'Stich'
];

const getCakeSizeOptions = (
  smallCakePrice: number,
  mediumCakePrice: number,
  largeCakePrice: number
) => [
  {
    id: 'S',
    label: `Tort mały (ok. 8 porcji) - ${smallCakePrice}.00zł`,
    labelInternal: 'Tort maly (ok. 8 porcji)'
  },
  {
    id: 'M',
    label: `Tort średni (ok. 12 porcji) - ${mediumCakePrice}.00 zł`,
    labelInternal: 'Tort sredni (ok. 12 porcji)'
  },
  {
    id: 'L',
    label: `Tort duży (ok. 16 porcji) - ${largeCakePrice}.00 zł`,
    labelInternal: 'Tort duzy (ok. 16 porcji)'
  }
];

export {
  foodSchema,
  foodFormInitialValues,
  cakeInitialValues,
  ballonWithNumberInitialValues,
  piniataInitialValues,
  piniataTypes,
  getCakeSizeOptions
};

export type { FoodFormData, BalloonWithNumber, Piniata, PiniataType, Cake };
